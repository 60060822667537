<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   大币种、小币种、版别   -->
      <div slot="itemCoinNameOrSmallCoinNameSlot" slot-scope="row">
        <span>{{ row.coinName + '-' + (row.coinItemName || '') + '-' + (row.coinItemVersionName || '') }}</span>
      </div>
<!--   性质   -->
      <div slot="itemNatureTypeSlot" slot-scope="row">
        <span :class="natureTypeClassType[row.natureType]">{{ natureTypeListMapText(row.natureType) }}</span>
      </div>
<!--   图片   -->
      <div slot="itemImageSlot" slot-scope="row">
        <div class="item-image-list" v-if="row.frontImage || row.backImage">
          <img class="item-image" :src="row.frontImage" alt="" @click="handleShowBigImage(row, 0)">
          <img class="item-image" :src="row.backImage" alt="" @click="handleShowBigImage(row, 1)">
        </div>
        <div class="flex-center-center mt-10">
          <a-button
              v-if="row.frontImage && row.backImage"
              type="primary"
              size="small"
              @click="handleCoverCutImg(row.frontImage, row.backImage, row.id)"
          >裁剪效果预览</a-button>
        </div>
      </div>
<!--   图片说明   -->
      <div slot="itemImageDescSlot" slot-scope="row">
        <a-tooltip>
          <template slot="title">
            <div v-html="row.descRemark"></div>
          </template>
          <div>{{ row.descRemark ?  (row.descRemark.slice(0, 20) + '...') : '' }}</div>
        </a-tooltip>
      </div>
<!--   属性   -->
      <div slot="itemAttrSlot" slot-scope="row">
        <div class="color-green">{{ row.quanpuBook }}</div>
        <div class="mt-10 color-blue">{{ computedText(row.quanpuBookSerial) }}</div>
        <div class="mt-10 color-red" v-if="row.weightSizeInfo && row.weightSizeInfo.size">φ≈{{ row.weightSizeInfo.size }}</div>
        <div class="mt-10 color-6699ff" v-if="row.weightSizeInfo && row.weightSizeInfo.weight">重量：{{ row.weightSizeInfo.weight }}g</div>
        <div class="mt-10 color-orange" v-if="row.grade">级别：{{ row.grade }}</div>
      </div>
<!--   评级信息   -->
      <div slot="itemRateDescSlot" slot-scope="row">
        <div v-if="row.ratingCompanyCode">评级编号：<span class="color-green">{{ row.ratingCompanyCode }}</span></div>
        <div class="mt-10" v-if="row.ratingCompanyType">评级公司：<span :class="ratingCompanyClassType[row.ratingCompanyType]">{{ computedRatingCompanyTypeListMapText(row.ratingCompanyType) }}</span></div>
        <div class="mt-10" v-if="row.box"><a-button type="link" size="small" @click="handleShowMoreRatingInfo(row.box)">查看更多评级信息</a-button></div>
        <div class="mt-10" v-if="!row.box && row.ratingCompanyCode">
          <a-button
              size="small"
              @click="handleReloadRatingInfo(row)"
          >重新获取评级信息</a-button>
        </div>
      </div>
<!--   成交信息   -->
      <div slot="itemTradeSlot" slot-scope="row">
        <div v-if="row.goodPriceInfo && row.goodPriceInfo.tradePrice" class="color-green">{{ row.goodPriceInfo.tradePrice }}元</div>
        <div v-if="row.goodPriceInfo && row.goodPriceInfo.tradePlatformType" class="mt-10" :class="tradingPlatformClassType[Number(row.goodPriceInfo.tradePlatformType)]">
          {{ computedTradingPlatformTypeMapText(Number(row.goodPriceInfo.tradePlatformType)) }}
        </div>
        <div v-if="row.goodPriceInfo && row.goodPriceInfo.score" class="color-black mt-10">分数：{{ row.goodPriceInfo.score }}</div>
        <div v-if="row.goodPriceInfo && row.goodPriceInfo.tradeTime" class="color-black mt-10">{{ row.goodPriceInfo.tradeTime }}</div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <div>
          <a-button
              type="primary"
              size="small"
              @click="handleEditor(row)"
              style='margin-left:5px'
          >编辑</a-button>
        </div>
        <div class="mt-10">
          <a-popconfirm
              title="确定删除吗？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleDeleteListItem(row)"
          >
            <a-button
                type="danger"
                size="small"
                style='margin-left:5px'
            >删除</a-button>
          </a-popconfirm>
        </div>
      </div>
    </a-table>
<!--  编辑  -->
    <EditorPopup ref="editorPopupEl" @success="handleSuccessEditor"/>
<!--  展示更多评级信息  -->
    <RatingInfoPopup ref="ratingInfoPopupEl"/>
<!--  抠图验证  -->
    <CutImgPopup ref="cutImgPopupEl" />
<!--  替换图片  -->
    <SetCoverPicPopup ref="setCoverPicPopupEl" @success="handleSuccessEditor"/>
  </div>
</template>
<script>

import {tableListDataByNature} from "@/views/cmsPage/coinBookManage/natureManage/_data"
import EditorPopup from "@/views/cmsPage/coinBookManage/natureManage/_components/EditorPopup/index.vue"
import {numberToString} from "@/untils/bookNumberMapText"
import {
  NATURE_TYPE_CLASS_TYPE,
  RATING_COMPANY_CLASS_TYPE, ratingCompanyTypeListMapText,
  TRADING_PLATFORM_CLASS_TYPE, tradingPlatformTypeMapText
} from "@/views/cmsPage/coinBookManage/_data"
import RatingInfoPopup from "@/views/cmsPage/coinBookManage/_components/RatingInfoPopup/index.vue"
import {objArrMapObj} from "@/untils/otherEvent"
import CutImgPopup from "@/views/cmsPage/coinBookManage/natureManage/_components/CutImgPopup/index.vue"
import SetCoverPicPopup from "@/views/cmsPage/coinBookManage/natureManage/_components/SetCoverPicPopup/index.vue"

export default {
  components: {
    SetCoverPicPopup,
    EditorPopup, RatingInfoPopup, CutImgPopup
  },
  props: ['pagination', 'list', "imgTypeList"],
  data() {
    return {
      selectedRowKeys: [],
      tableList: tableListDataByNature,
      natureTypeClassType: NATURE_TYPE_CLASS_TYPE,
      tradingPlatformClassType: TRADING_PLATFORM_CLASS_TYPE,
      ratingCompanyClassType: RATING_COMPANY_CLASS_TYPE,
    }
  },
  computed: {
    computedTradingPlatformTypeMapText: () => {
      return (value) => tradingPlatformTypeMapText(value)
    },
    computedRatingCompanyTypeListMapText: () => {
      return (value) => ratingCompanyTypeListMapText(value)
    }
  },
  methods: {
    /** 展示抠图数据，替换原图 */
    handleCoverCutImg(frontImg, backImg, id) {
      this.$refs.setCoverPicPopupEl.show(frontImg, backImg, id)
    },
    natureTypeListMapText(value) {
      return objArrMapObj('value', 'name', this.imgTypeList)[value]
    },
    /** 打开展示更多评级信息 */
    handleShowMoreRatingInfo(box) {
      this.$refs.ratingInfoPopupEl.show(box)
    },
    /** 计算泉蒲编号 */
    computedText(number) {
      return numberToString(number)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 放大图片 */
    handleShowBigImage(row, index) {
      this.$previewImg({
        current: index,
        list: [{img_url: row.frontImage}, {img_url: row.backImage}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
    /** 挪动 */
    handleChangeMove(row) {
      this.$message.warning('TODO....')
    },
    /** 编辑 */
    handleEditor(row) {
      this.$refs.editorPopupEl.show(row)
    },
    /** 编辑成功 */
    handleSuccessEditor() {
      this.$emit('editorSuccess')
    },
    /** 删除操作 */
    async handleDeleteListItem(row) {
      const res = await this.axios("/dq_admin/coinVersionNature/delById", {
        params: {
          id: row.id
        },
      })
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.$emit('editorSuccess')
    },
    /** 重新获取评级数据 */
    async handleReloadRatingInfo(row) {
      const res = await this.axios("/dq_admin/coinVersionNature/pullBoxData", {
        params: {
          id: row.id
        },
      });
      if (res.status !== '200') return
      this.$message.info(res.message)
      setTimeout(() => {
        this.handleSuccessEditor()
      }, 1000)
    },
  }
}
</script>
<style lang="scss" scoped>
.link-text {
  text-decoration: underline;
  color: #2d8cf0;
  cursor: pointer;
}
.item-image-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item-image {
    width: 100px;
  }
}
.color-red {
  color: red;
}
.color-orange {
  color: orange;
}
.color-green {
  color: green;
}
.color-blue {
  color: #2d8cf0;
}
.color-6699ff {
  color: #6699FF;
}
.red {
  color: #000c17 !important;
  background-color: #F3E5AB !important;
}
.orange {
  color: #000c17 !important;
  background-color: #FFE87C !important;
}
.green {
  color: #000c17 !important;
  background-color: #F2BB66 !important;
}
.gray {
  color: #000c17 !important;
  background-color: #E2A76F !important;
}
.gray-b {
  color: #000c17 !important;
  background-color: #EE9A4D !important;
}
.color-gray {
  color: gray;
}
.mt-10 {
  margin-top: 10px;
}
.action-btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.version-top {
  margin-bottom: 3px;
}
.cursor-pointer {
  margin-top: 3px;
  cursor: pointer;
}
</style>