<template>
  <a-modal
      v-model="showModal"
      title="裁剪效果预览"
      ok-text="确认图片替换"
      cancel-text="取消"
      :width="600"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="content-modal">
      <a-divider>原图</a-divider>
      <div class="mt-20 width-100">
        <a-spin :spinning="false" tip="Loading...">
          <div class="img-list">
            <div class="img-box">
              <img
                  v-if="frontImgUrlByYuan"
                  :src="frontImgUrlByYuan"
                  alt="" @click="handleShowBigImg([frontImgUrlByYuan, backImgUrlByYuan])"
              >
            </div>
            <div class="img-box">
              <img
                  v-if="backImgUrlByYuan"
                  :src="backImgUrlByYuan"
                  alt="" @click="handleShowBigImg([backImgUrlByYuan, frontImgUrlByYuan])"
              >
            </div>
          </div>
        </a-spin>
      </div>
      <a-divider>抠图之后的图片 <span class="color-red">（提示：替换时正反图都将替换！！！）</span></a-divider>
      <div class="mt-20 width-100" >
        <a-spin :spinning="cutImgSpinningStatus" tip="Loading...">
          <div class="img-list">
            <div class="img-box">
              <img
                  v-if="frontImgUrlByCut"
                  :src="frontImgUrlByCut"
                  alt="" @click="handleShowBigImg([frontImgUrlByCut, backImgUrlByCut])"
              >
            </div>
            <div class="img-box">
              <img
                  v-if="backImgUrlByCut"
                  :src="backImgUrlByCut"
                  alt="" @click="handleShowBigImg([backImgUrlByCut, frontImgUrlByCut])"
              >
            </div>
          </div>
        </a-spin>
      </div>
    </div>
  </a-modal>
</template>
<script>
import {getImgCut} from "@/axios/apis"
import {postSetCoverPicByBookDataApi} from "@/views/cmsPage/coinBookManage/_apis"

export default {
  data() {
    return {
      showModal: false,
      modalData: "",
      yuanImgSpinningStatus: true,
      cutImgSpinningStatus: true,
      frontImgUrlByYuan: "",
      backImgUrlByYuan: "",
      frontImgUrlByCut: "",
      backImgUrlByCut: "",
      id: undefined,
    }
  },
  methods: {
    /** 展示 */
    async show(frontImg, backImg, id) {
      this.frontImgUrlByYuan = frontImg
      this.backImgUrlByYuan = backImg
      this.id = id
      this.frontImgUrlByCut = ""
      this.backImgUrlByCut = ""
      this.showModal = true
      this.cutImgSpinningStatus = true
      await this.getCutImg(this.frontImgUrlByYuan, 'front');
      await this.getCutImg(this.backImgUrlByYuan, 'back');
      this.cutImgSpinningStatus = false
    },
    async getCutImg(imgUrl, type) {
      const res = await getImgCut(imgUrl)
      if (res.status !== "200") return;
      const result = res.data
      if (type === 'front') {
        this.frontImgUrlByCut = result ? result.cutoutImage : null
      } else if (type === 'back') {
        this.backImgUrlByCut = result ? result.cutoutImage : null
      }
    },
    /** 确定 */
    async handleConfirm() {
      if (this.cutImgSpinningStatus) return this.$message.warning("抠图图片还未成功，请稍后...");
      if (!this.frontImgUrlByCut) return this.$message.warning("正面抠图失败");
      if (!this.backImgUrlByCut) return this.$message.warning("反面抠图失败");
      const res = await postSetCoverPicByBookDataApi({
        id: this.id,
        cutFrontImage: this.frontImgUrlByCut,
        cutBackImage: this.backImgUrlByCut
      })
      if (res.status !== "200") return;
      this.$message.info("替换成功")
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.showModal = false
    },
    /** 展示大图 */
    handleShowBigImg(imgList) {
      this.$previewImg({
        list: [{ img_url: imgList[0] }, { img_url: imgList[1] }],
        baseImgField: "img_url",
        showMute: false,
      });
    }
  }
}
</script>
<style scoped lang="scss">
.top-title {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  color: #2d8cf0;
}
.content-modal {
  width: 100%;
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .img-box {
    padding: 10px;
    width: 220px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7c7a7a;
    img {
      width: 200px;
      //height: 200px;
    }
  }
}
</style>
