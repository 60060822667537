var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemCoinNameOrSmallCoinNameSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      row.coinName +
                        "-" +
                        (row.coinItemName || "") +
                        "-" +
                        (row.coinItemVersionName || "")
                    )
                  )
                ])
              ])
            }
          },
          {
            key: "itemNatureTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", { class: _vm.natureTypeClassType[row.natureType] }, [
                  _vm._v(_vm._s(_vm.natureTypeListMapText(row.natureType)))
                ])
              ])
            }
          },
          {
            key: "itemImageSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.frontImage || row.backImage
                  ? _c("div", { staticClass: "item-image-list" }, [
                      _c("img", {
                        staticClass: "item-image",
                        attrs: { src: row.frontImage, alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowBigImage(row, 0)
                          }
                        }
                      }),
                      _c("img", {
                        staticClass: "item-image",
                        attrs: { src: row.backImage, alt: "" },
                        on: {
                          click: function($event) {
                            return _vm.handleShowBigImage(row, 1)
                          }
                        }
                      })
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "flex-center-center mt-10" },
                  [
                    row.frontImage && row.backImage
                      ? _c(
                          "a-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleCoverCutImg(
                                  row.frontImage,
                                  row.backImage,
                                  row.id
                                )
                              }
                            }
                          },
                          [_vm._v("裁剪效果预览")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            }
          },
          {
            key: "itemImageDescSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-tooltip",
                    [
                      _c("template", { slot: "title" }, [
                        _c("div", {
                          domProps: { innerHTML: _vm._s(row.descRemark) }
                        })
                      ]),
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            row.descRemark
                              ? row.descRemark.slice(0, 20) + "..."
                              : ""
                          )
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemAttrSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "color-green" }, [
                  _vm._v(_vm._s(row.quanpuBook))
                ]),
                _c("div", { staticClass: "mt-10 color-blue" }, [
                  _vm._v(_vm._s(_vm.computedText(row.quanpuBookSerial)))
                ]),
                row.weightSizeInfo && row.weightSizeInfo.size
                  ? _c("div", { staticClass: "mt-10 color-red" }, [
                      _vm._v("φ≈" + _vm._s(row.weightSizeInfo.size))
                    ])
                  : _vm._e(),
                row.weightSizeInfo && row.weightSizeInfo.weight
                  ? _c("div", { staticClass: "mt-10 color-6699ff" }, [
                      _vm._v("重量：" + _vm._s(row.weightSizeInfo.weight) + "g")
                    ])
                  : _vm._e(),
                row.grade
                  ? _c("div", { staticClass: "mt-10 color-orange" }, [
                      _vm._v("级别：" + _vm._s(row.grade))
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemRateDescSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.ratingCompanyCode
                  ? _c("div", [
                      _vm._v("评级编号："),
                      _c("span", { staticClass: "color-green" }, [
                        _vm._v(_vm._s(row.ratingCompanyCode))
                      ])
                    ])
                  : _vm._e(),
                row.ratingCompanyType
                  ? _c("div", { staticClass: "mt-10" }, [
                      _vm._v("评级公司："),
                      _c(
                        "span",
                        {
                          class:
                            _vm.ratingCompanyClassType[row.ratingCompanyType]
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.computedRatingCompanyTypeListMapText(
                                row.ratingCompanyType
                              )
                            )
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                row.box
                  ? _c(
                      "div",
                      { staticClass: "mt-10" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowMoreRatingInfo(row.box)
                              }
                            }
                          },
                          [_vm._v("查看更多评级信息")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                !row.box && row.ratingCompanyCode
                  ? _c(
                      "div",
                      { staticClass: "mt-10" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleReloadRatingInfo(row)
                              }
                            }
                          },
                          [_vm._v("重新获取评级信息")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemTradeSlot",
            fn: function(row) {
              return _c("div", {}, [
                row.goodPriceInfo && row.goodPriceInfo.tradePrice
                  ? _c("div", { staticClass: "color-green" }, [
                      _vm._v(_vm._s(row.goodPriceInfo.tradePrice) + "元")
                    ])
                  : _vm._e(),
                row.goodPriceInfo && row.goodPriceInfo.tradePlatformType
                  ? _c(
                      "div",
                      {
                        staticClass: "mt-10",
                        class:
                          _vm.tradingPlatformClassType[
                            Number(row.goodPriceInfo.tradePlatformType)
                          ]
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.computedTradingPlatformTypeMapText(
                                Number(row.goodPriceInfo.tradePlatformType)
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                row.goodPriceInfo && row.goodPriceInfo.score
                  ? _c("div", { staticClass: "color-black mt-10" }, [
                      _vm._v("分数：" + _vm._s(row.goodPriceInfo.score))
                    ])
                  : _vm._e(),
                row.goodPriceInfo && row.goodPriceInfo.tradeTime
                  ? _c("div", { staticClass: "color-black mt-10" }, [
                      _vm._v(_vm._s(row.goodPriceInfo.tradeTime))
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "div",
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleEditor(row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除吗？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDeleteListItem(row)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-left": "5px" },
                            attrs: { type: "danger", size: "small" }
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            }
          }
        ])
      }),
      _c("EditorPopup", {
        ref: "editorPopupEl",
        on: { success: _vm.handleSuccessEditor }
      }),
      _c("RatingInfoPopup", { ref: "ratingInfoPopupEl" }),
      _c("CutImgPopup", { ref: "cutImgPopupEl" }),
      _c("SetCoverPicPopup", {
        ref: "setCoverPicPopupEl",
        on: { success: _vm.handleSuccessEditor }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }